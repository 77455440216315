import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import ContactTestForm from "../components/forms/ContactTestForm"

export default function contact({ data }) {

  return(
    <>
      <Seo title = "Contact TEST" />

      <Hero 
        headline = "Contact TEST"
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/about-hero.jpg" 
          alt="" 
          loading="eager"
        />
      </Hero>

      <div className="page-content">
        <div className="container">
          <div className="row">

            <div className="col-md-9">
              
              <ContactTestForm />
              
            </div>

          </div>
        </div>
      </div>
    </>
  )
}